<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <HeroSection :modulePage="modulePage" v-if="moduleRole('hero')" />

          <b-card v-if="moduleRole('kontak')">
            <template #header>
              <h5 class="card-title">Kontak</h5>
            </template>
            <div class="d-flex align-items-center">              
              <template v-if="showContent">
                <b-form-checkbox @change="updateShowSection($event, 'show-contact-contact', apiGet)" id="contact_desc" value="Y" unchecked-value="N" name="contact_desc" v-model="showContent">Show Section?</b-form-checkbox>
              </template>
              <b-button 
                variant="secondary"
                class="btn-rounded ml-auto"
                v-if="productCatOpen"
                @click="closeProductForm">
                Close <i class="fas fa-times ml-2"></i>
              </b-button>
              <b-button
                variant="outline-info"
                class="btn-rounded ml-auto"
                @click="showProductUpdate"
                v-else
              >
                Update Content <i class="fas fa-pencil-alt ml-2"></i>
              </b-button>

            </div>
            <hr />

            
            <validation-observer
              v-slot="{ handleSubmit }"
              ref="VFormClient"
              tag="div"
              v-if="productCatOpen"
            >
              <b-form @submit.prevent="handleSubmit(doSubmit)">
                <b-card class="border mb-0">
                  <template #header>
                    <h5 class="card-title">Content</h5>
                  </template>
                  <b-row class="mb-2">
                    <b-col md=4>
                      <div class="form-group">
                        <label class="control-label">Title (ID) <span class="text-danger">*</span></label>         
                        <b-form-input v-model="row.asc_title_id"  />
                        <VValidate 
                          name="Title (ID)" 
                          v-model="row.asc_title_id"
                          rules="required|min:3|max:160" 
                        />
                      </div>
                    </b-col>
                    <b-col md=4>
                      <div class="form-group">
                        <label class="control-label">Title (EN) <span class="text-danger">*</span></label>         
                        <b-form-input v-model="row.asc_title_en"  />
                        <VValidate 
                          name="Title (EN)" 
                          v-model="row.asc_title_en"
                          rules="required|min:3|max:160" 
                        />
                      </div>
                    </b-col>
                    <b-col md=4>
                      <div class="form-group">
                        <label class="control-label">Title (JP) <span class="text-danger">*</span></label>         
                        <b-form-input v-model="row.asc_title_jp"  />
                        <VValidate 
                          name="Title (JP)" 
                          v-model="row.asc_title_jp"
                          rules="required|min:3|max:160" 
                        />
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col md=4>
                      <div class="form-group">
                        <label class="control-label">Description (ID)</label>         
                        <b-form-textarea :rows="3" v-model="row.asc_content_id"  />
                        <VValidate 
                          name="Description (ID)" 
                          v-model="row.asc_content_id"
                          rules="min:3" 
                        />
                      </div>
                    </b-col>
                    <b-col md=4>
                      <div class="form-group">
                        <label class="control-label">Description (EN)</label>         
                        <b-form-textarea :rows="3" v-model="row.asc_content_en"  />
                        <VValidate 
                          name="Description (EN)" 
                          v-model="row.asc_content_en"
                          rules="min:3" 
                        />
                      </div>
                    </b-col>
                    <b-col md=4>
                      <div class="form-group">
                        <label class="control-label">Description (JP)</label>         
                        <b-form-textarea :rows="3" v-model="row.asc_content_jp"  />
                        <VValidate 
                          name="Description (JP)" 
                          v-model="row.asc_content_jp"
                          rules="min:3" 
                        />
                      </div>
                    </b-col>
                  </b-row>
                  <template #footer>
                    <div class="text-right">
                      <b-button variant="secondary" class="btn-rounded mr-3" @click="closeProductForm">Cancel</b-button>
                      <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
                    </div>
                  </template>
                </b-card>
              </b-form>
            </validation-observer>
            <div class="row justify-content-center" v-else>
              <div class="col-md-8 text-center">
                <h3>{{row.asc_title_id}}</h3>
                <div class="sect-heading__line"></div>
                <p>{{row.asc_content_id}}</p>
              </div>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'
import SEOSection from '@/components/SEOSection'
import HeroSection from '@/components/HeroSection'

export default {
  name: 'BoAboutUs',
  extends: GlobalVue,
  components: {
    HeroSection,
    SEOSection,
  },
  mounted() {
    this.apiGet()
  },
  data() {
    return {     
      productCatOpen: false,
      row: {},
      validation: {},
      showContent: null,
    }
  },
  computed: {
  },
  methods: {
    showProductUpdate(){
      this.productCatOpen = true
    },
    closeProductForm(){
      this.productCatOpen = false
    },
    updateShowSection(value, slug, callback){

      Gen.apiRest('/do/' + this.modulePage, {data: {type: 'updateShowSetting', value, slug}}).then(res => {
        if(res){
          this.$swal({
            title: res.data.message,
            icon: 'success',
          }).then(() => {
            callback()
          })
        }
      }).catch(err => {
        this.$swal({title: err.response.data.message, icon:'error'})
      })
    },
    doDelete(v, params = {}, callback){

      let id = v[params.idKey]
      let obj = params.obj||this.$route.meta.objName
      this.$swal({
        title: 'Delete this ' + obj + '?' || 'Data' + '?',
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.swalLoading()

          Gen.apiRest("/do/"+this.modulePage,{data:{type:params.type,id:id}}, "POST")
            .then((resp = {})=>{
              this.$swal({
                title: resp.data.message || 'Deleted Successfully!',
                icon: 'success',
              }).then(() => {
                callback()
              })
            })
            .catch(err=>{ 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response.data.title
                  err.message = err.response.data.message
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })        
        }
      })
    },
    doSubmit(){
      this.$refs.VFormClient.validate().then(success => {
        if(!success) return 

        this.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateDescription', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.productCatOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
  }
}
</script>
<style scoped>
.sect-heading__line {
  display: inline-block;
  width: 180px;
  height: 12px;
  border-radius: 20px;
  position: relative;
}
.sect-heading__line::before {
  content: '';
  position: absolute;
  top: 0;
  height: 60%;
  left: 60px;
  right: 60px;
  border-radius: 10px;
  background-color: #39768e;
}
</style>