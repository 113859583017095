<template>
	<b-container fluid>
		<bo-page-title />
		<section id="contact">
			<b-card v-if="isList" no-body>
				<template #header>
					<div class="d-flex align-items-center">
						<h5 class="card-title">Inbox</h5>

						<div class="ml-3">
							<b-button class="btn-rounded" size="sm" :variant="filterByStatus=='all'?'info':'outline-info'"
								:to="{name:$route.name, query:Object.assign({},$route.query||{}, {page:1, filterByStatus:'all'})}">
								All <span>({{ countData.all || 0 }})</span>
							</b-button>
							<b-button class="btn-rounded ml-1" size="sm" :variant="filterByStatus=='unread'?'info':'outline-info'"
								:to="{name:$route.name, query:Object.assign({}, $route.query||{}, {page:1, filterByStatus:'unread'})}">
								Unread <span>({{ countData.unread || 0 }})</span>
							</b-button>
							<b-button class="btn-rounded ml-1" size="sm" :variant="filterByStatus=='read'?'info':'outline-info'"
								:to="{name:$route.name, query:Object.assign({}, $route.query||{}, {page:1, filterByStatus:'read'})}">
								Read <span>({{ countData.read || 0 }})</span></b-button>
						</div>
					</div>
				</template>
				<b-card-body>
					<b-row>
						<b-col md>
						</b-col>
						<b-col md="auto">
							<div class="d-flex">
									<b-form @submit.prevent="doFilter()" class="d-flex">
									<b-input-group>
									<b-form-input v-model="filter.search" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
									<b-input-group-append>
										<b-button @click="doFilter()" variant="success"><i class="fas fa-search"></i></b-button>
									</b-input-group-append>
									</b-input-group>
									<b-button
										class="ml-1 d-inline-flex align-items-center"
										variant="outline-success"
										@click="doReset()"
									>Reset</b-button>
								</b-form>
							</div>
						</b-col>
					</b-row>
				</b-card-body>
				<b-table :fields="inboxFields" :items="dataList||[]" show-empty :busy="!dataList">
					<template #cell(num)="data">{{ data.index += 1 }}</template>
					<template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
					<template #cell(sender)="data">
						<div>{{ data.item.afc_fullname }}</div>
					</template>
					<template #cell(contact)="data">
						<span class="d-inline-block">
							<i class="ti-email mr-1"></i>
							<span>{{ data.item.afc_email }}</span>
						</span><br/>
						<span class="d-inline-block">
							<i class="icon-phone mr-1"></i>
							<span>{{ data.item.afc_phone }}</span>
						</span>
					</template>
					<template #cell(subject)="data">
						<div>{{ data.item.afc_subject }}</div>
					</template>
					<template #cell(date)="data">
						<div>{{ data.item.afc_created_date | moment('LLL') }}</div>
					</template>
					<template #cell(status)="data">
						<b-badge variant="success" v-if="data.item.afc_status == 'Y'">Read</b-badge>
						<b-badge variant="warning" v-else>Unread</b-badge>
					</template>
					<template #cell(actions)="data">
						<b-button v-if="moduleRole('detail')" variant="outline-info" class="btn-icon" v-b-tooltip.hover="'Detail'" @click="detailContact(data.item)"><i class="icon-eye"></i></b-button>
					</template>
					<template #empty>
						<div class="text-center">
							<b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
							<h4 align="center"><span v-if="Object.keys(filter).length">no data shown</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
						</div>
					</template>
				</b-table>
				<template #footer>
					<b-pagination v-if="data.total > data.per_page" class="mb-0 justify-content-end" v-model="pageNo"
						:per-page="data.per_page" :total-rows="data.total" />
				</template>
			</b-card>
			<b-modal
				id="contactDetail"
				title="Contact Detail"
				centered
				no-close-on-backdrop
			>
				<b-row class="gx-0 gy-2">
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Name</strong>
								<span>{{row.afc_fullname}}</span>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Phone Number</strong>
								<span>{{row.afc_phone}}</span>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Email</strong>
								<span>{{row.afc_email || '-'}}</span>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Subject</strong>
								<span>{{row.afc_subject}}</span>
							</div>
						</div>
					</b-col>
					<b-col cols="12">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Message</strong>
								<span style="white-space: pre-line;">{{row.afc_message}}</span>
							</div>
						</div>
					</b-col>
					<b-col md="12">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Status</strong>
								<div class="mt-1">
									<b-badge v-if="row.afc_status == 'N'" variant="warning">Unread</b-badge>
									<b-badge v-else-if="row.afc_status == 'Y'" variant="success">Read</b-badge>
								</div>
							</div>
						</div>  
					</b-col>
					<b-col md=12 v-if="row.afc_created_date">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Date</strong>
								<p v-if="row.afc_created_date">Created at: {{ row.afc_created_date | moment('LLLL') }}</p>
								<p v-if="row.afc_read_date">Read at: {{ row.afc_read_date | moment('LLLL') }}</p>
							</div>
						</div>
					</b-col>
				</b-row>
				<template #modal-footer>
					<b-button variant="primary" @click.prevent="closeModalDetail">Close</b-button>
				</template>
			</b-modal>
		</section>
	</b-container>
</template>

<script>
	import GlobalVue from '@/libs/Global.vue'
	import Gen from '@/libs/Gen'
	const _ = global._


	export default {
		name: 'BoContact',
		extends: GlobalVue,
		computed: {
			filterByStatus() {
				return this.$route.query.filterByStatus || "all"
			}
		},
		mounted() {
			this.$root.$on('bv::modal::hidden', () => {
				this.apiGet()
			})
			this.apiGet()
		},
		data() {
			return {
				countData: {},
				seoRow: {},
				heroEditorOpen: false,
				inboxFields: [{
					key: 'num',
					label: '#'
				}, 
				'sender', 
				'contact', 
				'subject', 
				'date',
				'status',
				'actions', ],
				filter: {},
			}
		},
		methods: {
			detailContact(item){
				this.row = _.clone(item)
				if(this.row.afc_status == 'N'){
					this.loadingOverlay = true
					Gen.apiRest("/do/"+ this.modulePage,
						{ data:{ type:'read', id: item.afc_id}}, 
						"POST"
					).then(() => {
						this.loadingOverlay = false
						this.$bvModal.show('contactDetail')
					}).catch(() => {
						this.loadingOverlay = false
						this.$bvModal.show('contactDetail')
					})
				}else{
					this.$bvModal.show('contactDetail')
				}
			},
			closeModalDetail(){
				this.row = {}
				this.$bvModal.hide('contactDetail')
			}
		},
		watch: {
			$route() {
				this.apiGet()
			},
			'$route.params.pageSlug': function () {
				this.$set(this, 'filter', {})
			},
		}
	}
</script>